import { OrderDetailsModel } from '../models/orderDetails.model';
import { PaymentMethodsEnum } from '../models/enums/paymentMethods.enum';
import { OrderRequestModel } from '../models/orderRequest.model';
import { AvailabilityLevelEnum } from '../models/enums/availabilityLevel.enum';
import { OrderTableDataModel } from '../models/orders/orderTableData.model';
import { parseOrderPrimaryGuest } from '../utils/formatter';

export const mapRequestCancelBodyAccordingToOrderDetails = (
  orderDetails: OrderDetailsModel,
  options?: { tripItemId?: number; refundMethod?: PaymentMethodsEnum },
) => {
  const { tripItemId, refundMethod } = options;
  const requestBody: any = {};

  requestBody.TripId = orderDetails.TripId;
  requestBody.PrimaryGuestName = orderDetails.PrimaryGuestName;
  requestBody.TripItemIds = tripItemId ? [tripItemId] : null; // null cancels all travel items

  if (!orderDetails.PaymentAmountDue) {
    // means that order is paid
    // the refundMethod will be there if it refund else undefined
    if (refundMethod) {
      requestBody.PaymentInfo = {
        PaymentMethod: refundMethod,
      };
    }
  }

  return requestBody;
};

export const mapOrderDetailsToRequest = (
  orderDetails: OrderDetailsModel,
  options: { testMode: boolean },
): OrderRequestModel => ({
  TripId: orderDetails.TripId,
  Reference: orderDetails.Reference,
  PrimaryGuestName: orderDetails.PrimaryGuestName,
  Guests: mapOrderDetailsToGuestsRequest(orderDetails),
  PaymentInfo: {
    TestMode: options.testMode,
  },
  InternalRemarks: orderDetails.InternalRemarks,
  CustomerRemarks: orderDetails.CustomerRemarks,
  Services: mapServicesDetailsToRequest(orderDetails.Services),
  Hotels: mapHotelsDetailsToRequest(orderDetails.Hotels),
  PassIds: orderDetails.PassIds,
  CouponIds: orderDetails.CouponIds,
  UniqueLink: orderDetails.UniqueLink,
  AdditionalAttributes: orderDetails.AdditionalAttributes,
});

const mapOrderDetailsToGuestsRequest = (
  orderDetails: OrderDetailsModel,
): OrderRequestModel['Guests'] =>
  orderDetails.Guests.map((guest) => ({
    ...guest,
    Email: guest.Email || null,
    Phone: guest.Phone || null,
  }));

const mapAvailabilityLevelToRequest = (
  availabilityLevel: AvailabilityLevelEnum,
) =>
  ({
    [AvailabilityLevelEnum.Confirmed]: AvailabilityLevelEnum.Available,
    [AvailabilityLevelEnum.OnRequest]: AvailabilityLevelEnum.Requestable,
  }[availabilityLevel]);

const mapServicesDetailsToRequest = (
  services: OrderDetailsModel['Services'],
): OrderRequestModel['Services'] =>
  services.map((service) => ({
    ServiceTime: service.ServiceTime,
    Guests: {
      Guests: service.Guests.map((guest) => ({
        GuestId: guest.GuestId,
        SupplierData: guest.SupplierData,
        Seat: guest.Seat,
        Price: guest.Price,
        BundledPrice: guest.BundledPrice,
        SellPrice: guest.SellPrice,
        Nights: guest.Nights,
      })),
      PrimaryGuestId: service.Guests[0].GuestId,
    },
    PickupPoint: {
      LocationName: service.PickupPoint.Name,
      LocationType: service.PickupPoint.LocationType,
      LocationId: service.PickupPoint.LocationId,
      Address: service.PickupPoint.Address,
      Time: service.PickupPoint.StartTime,
    },
    DropoffPoint: {
      LocationName: service.DropoffPoint.Name,
      LocationType: service.DropoffPoint.LocationType,
      LocationId: service.DropoffPoint.LocationId,
      Address: service.DropoffPoint.Address,
      Time: service.DropoffPoint.StartTime,
    },
    Duration: service.Duration,
    TripItemId: service.TripItemId,
    ProductType: service.ProductType,
    ProductId: service.ProductId,
    CategoryId: service.Category.Id,
    RatePlanId: service.RatePlan.Id,
    StartDate: service.StartDate,
    AvailabilityLevel: mapAvailabilityLevelToRequest(service.AvailabilityLevel),
    Price: service.Price,
    ConnectionId: service.ConnectionId,
    ProductMappingId: service.SupplierProductId,
    UnderReview: service.UnderReview,
    ConfirmationNumber: service.ConfirmationNumber,
    Supplements: service.Supplements,
    Nights: service.Nights,
  }));

const mapHotelsDetailsToRequest = (
  hotels: OrderDetailsModel['Hotels'],
): OrderRequestModel['Hotels'] =>
  hotels.map((hotel) => ({
    Rooms: hotel.Rooms.map((room) => ({
      RoomId: room.RoomId,
      Guests: {
        Guests: room.Guests.map((guest) => ({
          GuestId: guest.GuestId,
          SupplierData: guest.SupplierData,
          Price: guest.Price,
          BundledPrice: guest.BundledPrice,
          SellPrice: guest.SellPrice,
          Nights: guest.Nights,
        })),
        PrimaryGuestId: room.Guests[0].GuestId,
      },
      Nights: room.Nights.map((night) => ({
        NightId: night.NightId,
        BundledPrice: night.BundledPrice,
        Price: night.Price,
        SellPrice: night.SellPrice,
      })),
      SupplierData: room.SupplierData,
      BundledPrice: room.BundledPrice,
      SellPrice: room.SellPrice,
      Price: room.Price,
      Beds: room.Beds,
    })),
    TripItemId: hotel.TripItemId,
    ProductType: hotel.ProductType,
    ProductId: hotel.ProductId,
    CategoryId: hotel.Category.Id,
    RatePlanId: hotel.RatePlan.Id,
    StartDate: hotel.StartDate,
    AvailabilityLevel: mapAvailabilityLevelToRequest(hotel.AvailabilityLevel),
    Price: hotel.Price,
    ConnectionId: hotel.ConnectionId,
    ProductMappingId: hotel.SupplierProductId,
    UnderReview: hotel.UnderReview,
    ConfirmationNumber: hotel.ConfirmationNumber,
    Supplements: hotel.Supplements,
    Nights: hotel.Nights,
  }));

export const getEditedOrderRequest = (
  editedOrder: OrderTableDataModel,
  orderRequest: OrderRequestModel,
): OrderRequestModel => ({
  ...orderRequest,
  Guests: orderRequest.Guests.map((guest) => {
    const isEditGuest =
      (!editedOrder.guest && guest.PrimaryGuest) ||
      guest.GuestId === editedOrder.guest?.GuestId;

    if (isEditGuest) {
      const [firstName, lastName] = parseOrderPrimaryGuest(
        editedOrder.PrimaryGuestName,
      );

      return {
        ...guest,
        FirstName: firstName || null,
        LastName: lastName || null,
        Phone: editedOrder.PrimaryPhone || null,
        Email: editedOrder.PrimaryEmail || null,
      };
    }

    return guest;
  }),
  Services: orderRequest.Services.map((service) => ({
    ...service,
    ...getEditedConfirmation(editedOrder, service),
  })),
  Hotels: orderRequest.Hotels.map((hotel) => ({
    ...hotel,
    ...getEditedConfirmation(editedOrder, hotel),
  })),
});

const getEditedConfirmation = (
  editedOrder: OrderTableDataModel,
  product: OrderRequestModel['Hotels'][0] | OrderRequestModel['Services'][0],
) =>
  editedOrder.TripItemId === product.TripItemId &&
  editedOrder.Confirmation && {
    ConfirmationNumber: editedOrder.Confirmation,
  };
